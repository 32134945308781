import { getRegionForCountry } from '~/helpers/countries'
import type { CountryCode } from '~/settings'
import { Region } from '~/settings'

export function useCountry() {
  // The country negotiated from the geoip.server.ts plugin.
  const negotiatedCountry = useState<CountryCode | null>(
    'negotiatedCountry',
    () => null,
  )

  // Use an existing cookie.
  const countryCookie = useCookie<CountryCode>('exp_country', {
    default: () => {
      // Fallback to the negotiated country.
      return negotiatedCountry.value || 'CH'
    },
  })

  const country = computed({
    get() {
      return countryCookie.value
    },
    set(newValue: CountryCode) {
      countryCookie.value = newValue
    },
  })

  const region = computed<Region>(() => getRegionForCountry(country.value))
  const isImperial = computed<boolean>(() => region.value === Region.US)

  return { country, region, isImperial }
}
