import type { PageLanguage } from '#language-negotiation/language'
import { isValidLanguage } from '#language-negotiation/language'

export function getLanguageFromPath(path = ''): PageLanguage {
  if (path) {
    const matches = /^\/(de|fr|en)(\/|$)/.exec(path)
    const match = matches?.[1]
    if (match && isValidLanguage(match)) {
      return match
    }
  }
  return 'en'
}
