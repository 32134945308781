<template>
  <div class="container mt-60 md:mt-0">
    <SearchInput v-model="text" />
    <AppHeaderSearchResults v-if="text && isVisible" :text="text" />
  </div>
</template>

<script lang="ts" setup>
const text = ref('')

defineProps<{
  isVisible: boolean
}>()
</script>
