export const NODE_EDIT_ROLES = ['administrator', 'webmaster']
export const ANONYMOUS_USER = 'anonymous'
export const B2B_USER = ['b2b_user']

/**
 * The custom exped region.
 */
export enum Region {
  Switzerland = 'ch',
  DAFI = 'dafi',
  US = 'us',
  CA = 'ca',
  UK_IE = 'ukie',
  ROTW = 'rotw',
}

export const AVAILABLE_COUNTRIES = [
  'CH',
  'US',
  'DE',
  'AT',
  'FR',
  'IT',
  'GB',
  'IE',
  'CA',
] as const

export type CountryCode = (typeof AVAILABLE_COUNTRIES)[number]

export function validateCountry(v: unknown): v is CountryCode {
  return !!(
    v &&
    typeof v === 'string' &&
    AVAILABLE_COUNTRIES.includes(v as CountryCode)
  )
}
