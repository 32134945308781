<template>
  <ul v-if="data" class="mt-40 w-full lg:w-2/3 grid gap-30 lg:gap-50">
    <li v-for="hit in data.hits" :key="hit.id">
      <AppHeaderSearchItem v-bind="hit" />
    </li>
  </ul>
</template>

<script lang="ts" setup>
const props = defineProps<{
  text: string
}>()

const language = useCurrentLanguage()
const { region } = useCountry()
const { dataLayerPush } = useAnalyticsTracking()

const searchQuery = computed(() => ({
  language: language.value,
  region: region.value,
  text: props.text,
}))

const { data } = await useFetch('/api/search/content', {
  query: searchQuery,
})

watch(data, (newData) => {
  const total = newData?.total || 0
  dataLayerPush({
    event: 'siteSearch',
    searchTerm: props.text,
    resultCount: total,
  })
})
</script>
