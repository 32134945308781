export default defineNuxtPlugin({
  name: 'exped-track-switch-page',
  setup(nuxtApp) {
    const router = useRouter()
    const { trackSwitchPage, dataLayerPush } = useAnalyticsTracking()
    const language = useCurrentLanguage()
    const { region } = useCountry()

    // Where the dataLayer stuff is stored during SSR. We attach it to the
    // head after the page was rendered.
    const ssrDataLayer = useState<Record<string, string>[]>(
      'ssrDataLayer',
      () => [],
    )

    nuxtApp.hook('app:rendered', (ctx) => {
      ctx.ssrContext?.head.push({
        script: [
          {
            children: `window.dataLayer = ${JSON.stringify(
              ssrDataLayer.value,
            )}`,
            tagPriority: -100,
          },
        ],
      })
    })

    // The event is emitted by useDrupalRoute().
    nuxtApp.hook('drupal-route', (drupalRoute) => {
      trackSwitchPage(
        router.currentRoute.value,
        router.options.history.state.back?.toString(),
        {
          pageTitle: drupalRoute.metatags.title,
          entityType: drupalRoute.drupalRoute?.entityType || '',
          entityBundle: drupalRoute.drupalRoute?.entityBundle || '',
          entityId: drupalRoute.drupalRoute?.entityId || '',
        },
      )
    })

    router.afterEach((to, from) => {
      if (to.meta.drupalRoute) {
        return
      }
      setTimeout(() => {
        trackSwitchPage(to, from.fullPath, {
          pageTitle: document.title,
        })
      }, 500)
    })

    if (import.meta.client) {
      watch(language, (newLanguage) => {
        dataLayerPush({
          event: 'changeLanguage',
          pageLanguage: newLanguage,
        })
      })

      watch(region, (newRegion) => {
        dataLayerPush({
          event: 'changeCountry',
          pageCountry: newRegion,
        })
      })
    }
  },
})
