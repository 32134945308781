<template>
  <div class="language-switcher">
    <slot :countries="countries">
      <p class="is-small heavy">{{ $texts('regionSwitchLabel', 'Region') }}</p>
      <Dropdown :key="language" v-model="country" :options="countries" />
    </slot>
  </div>
</template>

<script lang="ts" setup>
import type { DropdownOption } from '~/components/Dropdown/index.vue'
import Dropdown from '~/components/Dropdown/index.vue'
import type { CountryCode } from '~/settings'

const { $texts } = useNuxtApp()
const { country } = useCountry()
const language = useCurrentLanguage()
const countryList = await useCountryData()

type CountryDropdownOption = DropdownOption & {
  value: CountryCode
}

const countries = computed<CountryDropdownOption[]>(() => {
  const countries = countryList.value.map((v) => {
    return {
      value: v.code,
      label: v.name,
    }
  })
  countries.push({
    value: 'ROTW' as any,
    label: $texts('otherCountriesLabel', 'Other Countries'),
  })

  return countries
})
</script>
