<template>
  <div
    class="overlay fixed top-0 left-0 size-full z-[50000000] bg-black/80 flex items-center justify-center will-change-transform"
  >
    <div
      ref="content"
      v-click-away="close"
      class="overlay-content z-[5010] relative size-full overflow-y-auto bg-white mx-auto p-20 pt-60"
      :class="{
        'h-full': fullwidth,
        'md:max-h-[80%] md:max-w-4/5': !fullwidth,
        'is-small-overlay': small,
      }"
    >
      <button
        aria-label="Close Overlay"
        class="overlay-btn-close absolute left-20 top-20 opacity-100 hover:opacity-60 transition-opacity duration-300 z-50"
        @click="close"
      >
        <ResponsiveSpriteSymbol name="cross" class="size-17" />
      </button>

      <slot />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onKeyStroke } from '@vueuse/core'
import { directive as vClickAway } from 'vue3-click-away'
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock'

defineProps<{
  fullwidth?: boolean
  small?: boolean
}>()

const emit = defineEmits(['close'])
const close = () => emit('close')
const content = ref<HTMLDivElement | null>(null)

onKeyStroke('Escape', close)
onMounted(() => {
  if (content.value) {
    disableBodyScroll(content.value, {
      reserveScrollBarGap: true,
    })
  }
})
onBeforeUnmount(clearAllBodyScrollLocks)
</script>

<style lang="postcss">
.is-small-overlay {
  @apply md:max-w-2/3 lg:p-60;
  width: fit-content;
  min-height: min-content;
  max-height: max-content;
  height: fit-content;
}

.overlay-enter-active,
.overlay-leave-active {
  backface-visibility: hidden;
  transition: 0.3s cubic-bezier(0.43, 0.72, 0.56, 0.99);
  .overlay-content {
    transition: 0.3s cubic-bezier(0.43, 0.72, 0.56, 0.99);
  }
}
.overlay-enter-from,
.overlay-leave-to {
  opacity: 0;
  .overlay-content {
    transform: scale(0.9);
  }
}
</style>
