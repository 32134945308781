import { useWindowSize } from '@vueuse/core'

export function useViewport() {
  const size = useWindowSize()

  const isMobileMenu = computed(() => {
    if (import.meta.server) {
      return false
    }
    return size.width.value < 1024
  })

  const isMobile = computed(() => {
    if (import.meta.server) {
      return false
    }
    return size.width.value < 768
  })

  const isMobileOrTablet = computed(() => {
    if (import.meta.server) {
      return false
    }
    return size.width.value < 1024
  })

  return { isMobileMenu, isMobileOrTablet, isMobile }
}
