import { Region } from '~/settings'

/**
 * Return the Region for a given country.
 */
export function getRegionForCountry(country: string): Region {
  switch (country) {
    case 'CH':
      return Region.Switzerland
    case 'US':
      return Region.US
    case 'FR':
    case 'IT':
    case 'DE':
    case 'AT':
      return Region.DAFI
    case 'IE':
    case 'GB':
      return Region.UK_IE
    case 'CA':
      return Region.CA
  }

  // Fallback to rest of the world.
  return Region.ROTW
}
