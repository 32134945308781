import validate from "/builds/bazinga/exped/frontend/node_modules/nuxt/dist/pages/runtime/validate.js";
import navigate_45from_45error_45global from "/builds/bazinga/exped/frontend/middleware/navigateFromError.global.ts";
import user_45login_45redirect_45global from "/builds/bazinga/exped/frontend/middleware/userLoginRedirect.global.ts";
import manifest_45route_45rule from "/builds/bazinga/exped/frontend/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  navigate_45from_45error_45global,
  user_45login_45redirect_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}