<template>
  <nav
    v-if="data"
    class="menu-nav container mt-50 md:mt-0"
    :aria-label="$texts('header.ariaMenuLabel', 'Main Navigation')"
  >
    <AppHeaderMenuGroup v-bind="data.categories" />
    <AppHeaderMenuGroup v-bind="data.activities" />
    <AppHeaderMenuGroup v-bind="data.main" class="is-main" />

    <ClientOnly>
      <div class="menu-group is-meta pt-130">
        <CountrySwitcher />
        <LanguageSwitcher class="mt-40" />
      </div>
    </ClientOnly>
  </nav>
</template>

<script lang="ts" setup>
const language = useCurrentLanguage()

const { data } = await useAsyncGraphqlQuery('mainMenu', null, {
  graphqlCaching: {
    client: true,
  },
  transform: function (data) {
    return data.data
  },
  watch: [language],
})
</script>

<style lang="postcss">
.menu-nav {
  @apply grid md:grid-cols-3;
  @screen md {
    grid-template-rows: auto 1fr;
  }

  .menu-group {
    @apply md:row-span-2;
    &.is-main {
      @apply md:col-start-3 md:row-span-1;
      button {
        @apply md:hidden;
      }
    }
    &.is-meta {
      @apply md:col-start-3 md:row-span-2;
    }
  }
}
</style>
