import { getLanguageFromPath } from '~/helpers/language'

/**
 * Redirect to the user's page or the Drupal login page for /login routes.
 */
export default defineNuxtRouteMiddleware((to) => {
  if (
    to.path === '/en/login' ||
    to.path === '/de/login' ||
    to.path === '/fr/login' ||
    to.path === '/login'
  ) {
    // Redirect to the user page.
    // Passing the open options makes sure the redirect is always done using a
    // page refresh.
    const language = getLanguageFromPath(to.path)
    return navigateTo(`/${language}/user`, {
      redirectCode: 301,
      open: {
        target: '_self',
      },
    })
  }
})
