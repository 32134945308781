import type { RouteLocationNormalizedLoaded } from 'vue-router'

type AnalyticsEventSwitchPage = {
  event: 'switchPage'
  pageURL: string
  pagePath: string
  routeName: string
  pageReferrer?: string
  pageTitle?: string
  entityType?: string
  entityBundle?: string
  entityId?: string
}

export default function () {
  const ssrDataLayer = useState<any[]>('ssrDataLayer', () => [])
  const language = useCurrentLanguage()
  const { region } = useCountry()
  const router = useRouter()
  const dataLayerPush = (data: any) => {
    if (import.meta.client) {
      window.dataLayer.push(data)
    }

    if (import.meta.server) {
      ssrDataLayer.value.push(data)
    }
  }
  function trackSwitchPage(
    route: RouteLocationNormalizedLoaded,
    pageReferrer: string | undefined | null,
    additional: Partial<AnalyticsEventSwitchPage>,
  ) {
    const requestUrl = useRequestURL()
    dataLayerPush({
      event: 'switchPage',
      pageURL: requestUrl.origin + route.fullPath,
      pagePath: route.fullPath,
      pageLanguage: language.value,
      pageCountry: region.value,
      pageReferrer,
      entityType: '',
      entityBundle: '',
      entityId: '',
      routeName: (router.currentRoute.value.name || '')
        .toString()
        .split('___')[0],
      ...additional,
    })
  }

  return { trackSwitchPage, dataLayerPush }
}

declare global {
  interface Window {
    dataLayer: {
      push: (e: any) => void
    }
  }
}
