<template>
  <div class="language-switcher">
    <slot :language-links="linksMapped">
      <p class="is-small heavy">
        {{ $texts('languageSwitchLabel', 'Language') }}
      </p>
      <Dropdown :options="linksMapped" :model-value="language" />
    </slot>
  </div>
</template>

<script lang="ts" setup>
import type { PageLanguage } from '#language-negotiation/language'
import type { RouteLocationNamedRaw } from 'vue-router'

const language = useCurrentLanguage()
const links: ComputedRef<
  { code: string; active: boolean; to: RouteLocationNamedRaw }[]
> = useLanguageLinks()

const LABEL: Record<PageLanguage, string> = {
  en: 'English',
  de: 'Deutsch',
  fr: 'Francais',
}

const linksMapped = computed<
  {
    value: string
    label: string
    href: RouteLocationNamedRaw
    active: boolean
  }[]
>(() =>
  links.value.map((v) => {
    return {
      value: v.code,
      label: LABEL[v.code as PageLanguage],
      href: v.to,
      active: language.value === v.code,
    }
  }),
)
</script>
