import { defineNuxtPlugin } from '#app'

/**
 * Loads data about the current session.
 *
 * Only happens during SSR.
 */
export default defineNuxtPlugin(async () => {
  const { fetched } = useDrupalUser()

  // If the config has already been fetched, return.
  // This is the case when we're client-side, because the config is already
  // shipped via payload from the server.
  if (fetched.value) {
    return
  }

  const { data } = await useGraphqlQuery('drupalUser')
  if (data && data.currentDrupalUser) {
    setDrupalUserState(data.currentDrupalUser)
  }
})
