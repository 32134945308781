
export const DPR = ["","1.5","2"]

export const VIEWPORTS = {
  'sm': 480,
  'md': 768,
  'lg': 1140,
  'xs': 335,
  'xl': 1365,
  '2xl': 1800,
  '3xl': 2570
} as const

export type Viewport = keyof typeof VIEWPORTS