import { default as indexcdFEUeAW4eMeta } from "/builds/bazinga/exped/frontend/pages/[...slug]/index.vue?macro=true";
import { default as indexyWwfjg7wObMeta } from "/builds/bazinga/exped/frontend/pages/faqs/index.vue?macro=true";
import { default as indexYNpj5e6F6FMeta } from "/builds/bazinga/exped/frontend/pages/groups/index.vue?macro=true";
import { default as indexSLOCQFkJnnMeta } from "/builds/bazinga/exped/frontend/pages/media/[id]/index.vue?macro=true";
import { default as indexj94XciaWSVMeta } from "/builds/bazinga/exped/frontend/pages/newsroom/index.vue?macro=true";
import { default as indexZPxmhCm7Z7Meta } from "/builds/bazinga/exped/frontend/pages/products/[category]/[product]/index.vue?macro=true";
import { default as indexQskJVfbv6EMeta } from "/builds/bazinga/exped/frontend/pages/products/[category]/index.vue?macro=true";
import { default as indexSQRRTaqso5Meta } from "/builds/bazinga/exped/frontend/pages/sitemap/index.vue?macro=true";
import { default as component_45stubhU8U5zw8lMMeta } from "/builds/bazinga/exped/frontend/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubhU8U5zw8lM } from "/builds/bazinga/exped/frontend/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "drupal-route",
    path: "/:slug(.*)*",
    meta: indexcdFEUeAW4eMeta || {},
    component: () => import("/builds/bazinga/exped/frontend/pages/[...slug]/index.vue")
  },
  {
    name: "faqs",
    path: "/de/faqs",
    meta: indexyWwfjg7wObMeta || {},
    component: () => import("/builds/bazinga/exped/frontend/pages/faqs/index.vue")
  },
  {
    name: "groups",
    path: "/groups",
    meta: indexYNpj5e6F6FMeta || {},
    component: () => import("/builds/bazinga/exped/frontend/pages/groups/index.vue")
  },
  {
    name: "media-id",
    path: "/media/:id()",
    component: () => import("/builds/bazinga/exped/frontend/pages/media/[id]/index.vue")
  },
  {
    name: "newsroom",
    path: "/de/newsroom",
    meta: indexj94XciaWSVMeta || {},
    component: () => import("/builds/bazinga/exped/frontend/pages/newsroom/index.vue")
  },
  {
    name: "product-detail",
    path: "/en/products/:category/:product",
    meta: indexZPxmhCm7Z7Meta || {},
    component: () => import("/builds/bazinga/exped/frontend/pages/products/[category]/[product]/index.vue")
  },
  {
    name: "product-category-list",
    path: "/en/products/:category",
    meta: indexQskJVfbv6EMeta || {},
    component: () => import("/builds/bazinga/exped/frontend/pages/products/[category]/index.vue")
  },
  {
    name: "sitemap",
    path: "/sitemap",
    component: () => import("/builds/bazinga/exped/frontend/pages/sitemap/index.vue")
  },
  {
    name: component_45stubhU8U5zw8lMMeta?.name,
    path: "/cart",
    component: component_45stubhU8U5zw8lM
  },
  {
    name: component_45stubhU8U5zw8lMMeta?.name,
    path: "/user/login",
    component: component_45stubhU8U5zw8lM
  }
]