import type { EasyTextsFragment } from '#build/graphql-operations'
import { defineEasyTextsLoader } from '#nuxt-easy-texts/types'

function getTranslations(
  translations?: EasyTextsFragment,
): Record<string, string | [string, string]> {
  if (!translations) {
    return {}
  }
  return Object.entries(translations).reduce<
    Record<string, string | [string, string]>
  >((acc, [fullKey, value]) => {
    const keyWithDots = fullKey.replace('__', '.')
    if (typeof value === 'string') {
      acc[keyWithDots] = value
    } else if (typeof value === 'object' && value.plural && value.singular) {
      acc[keyWithDots] = [value.singular, value.plural]
    }
    return acc
  }, {})
}

export default defineEasyTextsLoader(() => {
  const language = useCurrentLanguage()
  return {
    async load() {
      return useGraphqlQuery('loadTranslations').then(function (data) {
        return getTranslations(data.data.translations)
      })
    },
    reloadTrigger() {
      return computed(() => language.value)
    },
  }
})
