/**
 * Workaround for https://github.com/nuxt/nuxt/issues/22683
 *
 * When on an error page and clicking on any link the error page will not
 * disappear due to a Nuxt bug.
 *
 * This middleware fixes this by triggering a full page reload if there is an
 * active error.
 */
export default defineNuxtRouteMiddleware((to, from) => {
  const error = useError()

  // Make sure we're client side, have an error and are going to another page.
  if (
    import.meta.client &&
    error.value &&
    to &&
    from &&
    to.path !== from.path
  ) {
    // Additionally check that the error page is currently being rendered.
    const isOnErrorPage = !!document.getElementById('nuxt-error-page')
    if (isOnErrorPage) {
      // Trigger a full page load in the browser.
      window.location.href = to.fullPath
    }
  }
})
