<template>
  <Transition name="menu">
    <div
      v-show="visible"
      ref="content"
      class="absolute left-0 w-full z-[90] pt-20 lg:pt-60 xl:pt-90 overflow-auto"
      :style="overlayStyle"
    >
      <slot />
    </div>
  </Transition>
</template>

<script lang="ts" setup>
import { disableBodyScroll } from 'body-scroll-lock'

const props = defineProps<{
  top: number
  visible: boolean
}>()

const content = ref<HTMLDivElement | null>(null)

watch(
  () => props.visible,
  (isVisible) => {
    if (!content.value) {
      return
    }
    if (isVisible) {
      disableBodyScroll(content.value, {
        reserveScrollBarGap: true,
      })
    }
  },
)

const overlayStyle = computed(() => {
  return {
    top: props.top + 'px',
    height: `calc(100vh - ${props.top}px)`,
  }
})
</script>
