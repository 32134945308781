<template>
  <NuxtLink
    :to="link"
    class="ml-auto p-10 origin-top-right logo md:absolute md:top-10 md:right-10 lg:right-25 lg:top-20 md:hover:opacity-60"
  >
    <img
      src="~/assets/brand-assets/Logo.svg?url"
      alt="EXPED"
      class="lg:h-[30px] 3xl:h-50"
    />
  </NuxtLink>
</template>

<script lang="ts" setup>
const language = useCurrentLanguage()

const link = computed(() => `/${language.value}`)
</script>

<style lang="postcss">
.logo {
  @screen md {
    transform: rotate(90deg) translateX(100%);
  }
}
</style>
