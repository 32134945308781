<template>
  <div class="search-input flex w-full lg:w-2/3 border-b-1 items-end">
    <label for="search-menu-input" class="sr-only">{{
      $texts('searchInputFieldLabel', 'Search')
    }}</label>
    <input
      id="search-menu-input"
      :value="modelValue"
      :placeholder="$texts('searchInputPlaceholder', 'Search')"
      class="w-full pr-10 text-lg lg:text-2xl font-bold outline-none pt-20 pb-5"
      @input="onInput"
    />
    <ResponsiveSpriteSymbol name="search" class="size-20 mb-10" />
  </div>
</template>

<script lang="ts" setup>
const props = defineProps<{
  modelValue: string
}>()

const emit = defineEmits(['update:modelValue'])

let timeout: any = null

// Clear any pending input timeout if the value is updated.
watch(
  () => props.modelValue,
  () => clearTimeout(timeout),
)

function onInput(e: Event) {
  clearTimeout(timeout)
  timeout = setTimeout(() => {
    if (e.target instanceof HTMLInputElement) {
      emit('update:modelValue', e.target.value)
    }
  }, 400)
}
</script>
