<template>
  <template v-if="mobileIcon">
    <SpriteSymbol :name="mobileIcon" :class="[$attrs.class, 'lg:hidden']" />
    <SpriteSymbol :name="name" :class="[$attrs.class, 'until-lg:hidden']" />
  </template>
  <SpriteSymbol v-else :name="name" :class="$attrs.class" />
</template>

<script lang="ts" setup>
import type { NuxtSvgSpriteSymbol } from '#nuxt-svg-sprite/runtime'

const props = defineProps<{
  name: NuxtSvgSpriteSymbol
}>()

const MOBILE_ICONS: Partial<Record<NuxtSvgSpriteSymbol, NuxtSvgSpriteSymbol>> =
  {
    pin: 'pin_mobile',
    mail: 'mail_mobile',
    menu: 'menu_mobile',
    plus: 'plus_mobile',
    arrow: 'arrow_mobile',
    cross: 'cross_mobile',
    minus: 'minus_mobile',
    bullet: 'bullet_mobile',
    search: 'search_mobile',
    download: 'download_mobile',
    'chevron-up': 'chevron-up_mobile',
    'chevron-down': 'chevron-down_mobile',
    'chevron-left': 'chevron-left_mobile',
    'chevron-right': 'chevron-right_mobile',
  }

const mobileIcon = computed(() => MOBILE_ICONS[props.name])
</script>
