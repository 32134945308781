import type { CountryTerm } from '~/types/country'

export async function useCountryData(): Promise<ComputedRef<CountryTerm[]>> {
  const language = useCurrentLanguage()
  const data = await useFetch('/api/countryData', {
    query: {
      language,
    },
    deep: false,
    dedupe: 'defer',
  })

  return computed(() => data.data.value || [])
}
