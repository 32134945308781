<template>
  <NuxtLink :to="url" class="flex gap-17 md:items-center">
    <div class="w-[80px] md:w-[120px] lg:w-[180px] order-last md:order-first">
      <RokkaImage
        v-bind="image"
        :hash="image?.rokka?.hash ?? ''"
        :config="imageStyle"
      />
    </div>
    <div class="flex-1">
      <h3 class="title is-3">{{ title }}</h3>
      <p class="mb-10">{{ productName }}</p>
      <p v-html="description" />
    </div>
  </NuxtLink>
</template>

<script lang="ts" setup>
import type { ImageFileFragment } from '#graphql-operations'
import { RokkaImage } from '#components'

const props = defineProps<{
  id: string
  url: string
  title: string
  description: string
  teaserImage: string
  bundle: string
  productName?: string
  entityType: string
}>()

const imageStyle = defineImageStyle({
  type: 'sizes',
  sizes: {
    xs: 80,
    md: 120,
    lg: 180,
  },
})

const image = computed<ImageFileFragment | null>(() => {
  if (props.teaserImage) {
    return {
      label: props.id + '.jpg',
      rokka: {
        hash: props.teaserImage,
      },
    }
  }

  return null
})
</script>
