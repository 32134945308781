import type {
  DrupalUserFragment,
  DrupalUserGroupFragment,
} from '#graphql-operations'

interface DrupalUserState {
  id: string
  fetched: boolean
  accessToolbar: boolean
  canUseParagraphsBuilder: boolean
  roles: string[]
  groups: DrupalUserGroupFragment[]
}

interface UseDrupalUser {
  fetched: globalThis.ComputedRef<boolean>
  isLoggedIn: globalThis.ComputedRef<boolean>
  canAccessToolbar: globalThis.ComputedRef<boolean>
  canUseParagraphsBuilder: globalThis.ComputedRef<boolean>
  groups: globalThis.ComputedRef<DrupalUserGroupFragment[]>
  roles: globalThis.ComputedRef<string[]>
}

function useDrupalUserState(): globalThis.Ref<DrupalUserState> {
  return useState<DrupalUserState>('drupalUser', () => ({
    id: '',
    fetched: false,
    accessToolbar: false,
    canUseParagraphsBuilder: false,
    roles: [],
    groups: [],
  }))
}

export function setDrupalUserState(data?: DrupalUserFragment) {
  const drupalUser = useDrupalUserState()
  drupalUser.value.fetched = true
  if (!data) {
    return
  }
  drupalUser.value.id = data.id || ''
  drupalUser.value.accessToolbar = !!data.canAccessToolbar
  drupalUser.value.canUseParagraphsBuilder = !!data.canUseParagraphsBuilder
  drupalUser.value.roles = data.roles || []
  drupalUser.value.groups = data.groups || []
}

export function useDrupalUser(): UseDrupalUser {
  const user = useDrupalUserState()

  const fetched = computed(() => user.value.fetched)
  const isLoggedIn = computed(() => !!user.value.id)
  const canAccessToolbar = computed(() => user.value.accessToolbar)
  const canUseParagraphsBuilder = computed(
    () => user.value.canUseParagraphsBuilder,
  )
  const groups = computed(() => user.value.groups)
  const roles = computed(() => user.value.roles)

  return {
    fetched,
    canAccessToolbar,
    canUseParagraphsBuilder,
    isLoggedIn,
    groups,
    roles,
  }
}
